//utils
import { config } from './config'
import {
  logThis
} from './utils'
//svg
import { feetSx, feetDx } from "./svg";
import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// gsap.registerPlugin(ScrollTrigger);

const heroAnimation = () => {
  if (config.debug) logThis("init heroAnimation");
  gsap.fromTo(`.collage-el`,
    {
      opacity: 0,
      scale: 0.8,
      ease: "ease-in-out",
    },
    {
      scale: 1,
      opacity: 1,
      duration: 0.5,
      stagger: 0.2,
      delay: 0.3,
  
    }
  )

  gsap.fromTo(`#hero-text`,
    {
      opacity: 0,
      ease: "ease-in-out",
    },
    {
      opacity: 1,
      duration: 0.8,
      delay: 1.5,
      onComplete: () => {
        document.body.style.overflow = 'auto';
      }    
    }
  )


  // var tl = gsap.timeline({ repeat: 100, repeatDelay: 0 });
  // var tl1 = gsap.timeline({ repeat: 100, repeatDelay: 0, yoyo: true });
  // const waitColor = durationOscillation * 6 - durationColor;

  // tl.to(".logo-containersecond-color",
  //   {
  //     fill: colors[0],
  //     duration: durationColor
  //   }, 0)
  // tl.to(".logo-containersecond-color",
  //   {
  //     fill: colors[1],
  //     duration: durationColor
  //   }, waitColor + durationColor)
  // tl.to(".logo-containersecond-color",
  //   {
  //     fill: colors[2],
  //     duration: durationColor
  //   }, 1 * waitColor + 2 * durationColor)
  // tl.to(".logo-containersecond-color",
  //   {
  //     fill: colors[3],
  //     duration: durationColor
  //   }, 2 * waitColor + 3 * durationColor)
  // tl.to(".logo-containersecond-color",
  //   {
  //     fill: colors[4],
  //     ease: "ease-in-out",
  //     duration: durationColor
  //   }, 3 * waitColor + 4 * durationColor)

  // tl1.to("#desk-logo",
  //   {
  //     skewX: '0deg',
  //     skewY: '0deg',
  //     ease: "ease-in-out",
  //     duration: durationOscillation
  //   }
  // )
  // tl1.to("#desk-logo",
  //   {
  //     skewX: '10deg',
  //     skewY: '-10deg',
  //     ease: "ease-in-out",
  //     duration: durationOscillation
  //   }
  // )
  // tl1.to("#desk-logo",
  //   {
  //     skewX: '-10deg',
  //     skewY: '10deg',
  //     ease: "ease-in-out",
  //     duration: durationOscillation
  //   }
  // )


  /*
  toggleActions: | enter | exit | comeback on screen | all the way back past the start | with these options "play pause resume reverse restart reset complete none"
  start: | first element relates to the trigger element | second value relates to the scroller | e.g."top center" top of the trigger element reach the center of the viewport. options "top center bottom" o "pixels percentage (relative to top)"
  end: everything that applay to start, plus select a value relative to the start. e.g. "+=300px" or a funtion e.g. ()=> "+=" + document.getElementById(globalWrapper).offsetWidth
  endTrigger: we can have a different element as end trigger
  */

  // ScrollTrigger.defaults({
  //   markers: false,
  // });
  // ScrollTrigger.create({
  //   animation: tl,
  //   toggleActions: "play play reverse reverse",
  //   trigger: `#${dbContainerId}`,
  //   start: "-20% 20%",
  //   endTrigger: `#${globalWrapper}`,
  //   end: "bottom 70%",
  //   // end: `+=${globalWrapperElHeight * 0.9}px`,
  //   // scrub: 1,  
  //   // pinSpacing: true,
  //   // pin: true,
  //   //scroller: "#container", //custom scroll element
  //   //horizontal: true,
  //   // id: "my-id",
  //   // toggleClass: "active", //the class can be added to any element
  //   //onUpdate: (self) => console.log("update", self.progress.toFixed(3)),
  //   //onToggle: (self) => console.log("update", self.isActive),
  //   //onEnter: () => console.log("enter"),
  //   //onLeave: () => console.log("leave"),
  //   //onEnterBack: () => console.log("enter back"),
  //   //onLeaveBack: () => console.log("all the way back")
  // })
}
export default heroAnimation;
