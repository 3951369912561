//utils
import { config } from './config'
import {
  logThis
} from './utils'
//svg
import { gsap } from "gsap";

const showThe4i = (iiiiContainerId, duration, iiiiText) => {
  if (config.debug) logThis("init showThe4i for hero: " + iiiiContainerId);
  const iiiiContainerEl = document.getElementById(iiiiContainerId);

  let textCounter = 1;

  gsap.fromTo(`#${iiiiContainerId}`,
    {
      opacity: 0,
      x: "-30%",
      ease: "ease-in-out",
    },
    {
      opacity: 1,
      x: 0,
      duration: duration,
      repeat: 100,
      onRepeat: () => {
        iiiiContainerEl.innerHTML = iiiiText[textCounter];
        textCounter++
        if (textCounter > iiiiText.length - 1) textCounter = 0;
      }
    }
  )

}
export default showThe4i;
