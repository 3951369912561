//config
import { config } from '../js/config'
//utils
import { logThis } from '../js/utils'
//custom code
import handlePopupOnHover from '../js/handlePopupOnHover'
import feetAnimation from '../js/feetAnimation'
import heroAnimation from '../js/heroAnimation'
import showThe4i from '../js/showThe4i'

//style

window.addEventListener('load', (event) => {
  if (config.debug) logThis('page is fully loaded');
  const feets = [
    {
      orientation: "sx",
      color: 4,
      size: 90,
      rotate: 110,
      x: -30,
      y: 0,
    },
    {
      orientation: "dx",
      color: 3,
      size: 77,
      rotate: 110,
      x: 70,
      y: 70,
    },
    {
      orientation: "sx",
      color: 0,
      size: 90,
      rotate: 110,
      x: 95,
      y: 125,
    },
    {
      orientation: "dx",
      color: 0,
      size: 77,
      rotate: 110,
      x: 220,
      y: 130,
    },
    {
      orientation: "sx",
      color: 4,
      size: 90,
      rotate: 110,
      x: 240,
      y: 190,
    },
    {
      orientation: "dx",
      color: 1,
      size: 77,
      rotate: 130,
      x: 360,
      y: 210,
    },
    {
      orientation: "sx",
      color: 0,
      size: 90,
      rotate: 140,
      x: 410,
      y: 240,
    },  
    {
      orientation: "dx",
      color: 0,
      size: 77,
      rotate: 110,
      x: 520,
      y: 180,
    },  
    {
      orientation: "sx",
      color: 4,
      size: 90,
      rotate: 110,
      x: 540,
      y: 230,
    },          
  ]
  feetAnimation('hero-feet-anim', feets, 0.7);
  const feetsActiveFloor = [
    {
      orientation: "sx",
      color: 4,
      size: 90,
      rotate: 110,
      x: -30,
      y: 0,
    },
    {
      orientation: "dx",
      color: 3,
      size: 77,
      rotate: 110,
      x: 70,
      y: 70,
    },
    {
      orientation: "sx",
      color: 0,
      size: 90,
      rotate: 110,
      x: 95,
      y: 125,
    },
    {
      orientation: "dx",
      color: 0,
      size: 77,
      rotate: 110,
      x: 220,
      y: 130,
    },
    {
      orientation: "sx",
      color: 4,
      size: 90,
      rotate: 110,
      x: 240,
      y: 190,
    },
    {
      orientation: "dx",
      color: 1,
      size: 77,
      rotate: 130,
      x: 360,
      y: 210,
    },
    {
      orientation: "sx",
      color: 0,
      size: 90,
      rotate: 140,
      x: 410,
      y: 240,
    },  
    {
      orientation: "dx",
      color: 0,
      size: 77,
      rotate: 110,
      x: 520,
      y: 180,
    },  
    {
      orientation: "sx",
      color: 4,
      size: 90,
      rotate: 110,
      x: 540,
      y: 230,
    },          
    {
      orientation: "dx",
      color: 3,
      size: 77,
      rotate: 110,
      x: 660,
      y: 200,
    },  
    {
      orientation: "sx",
      color: 1,
      size: 90,
      rotate: 110,
      x: 700,
      y: 260,
    },          
  ]
  feetAnimation('active-floor-feet-anim', feetsActiveFloor, 0.7);
  heroAnimation();
  const iiiiText = [
    "inclusivi",
    "interattivi",
    "interdisciplinari",
    "innovativi",
  ]  
  showThe4i('le-4i', 1.5, iiiiText);
  // handlePopupOnHover('container-strong-consistency', 'popup-on-hover-strong-consistency-1', 'popup-strong-consistency', 0)
  // handlePopupOnHover('container-strong-consistency', 'popup-on-hover-strong-consistency-2', 'popup-strong-consistency', 0)
});
