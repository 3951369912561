//utils
import { config } from './config'
import {
  logThis
} from './utils'
//svg
import { feetSx, feetDx } from "./svg";
import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// gsap.registerPlugin(ScrollTrigger);

const feetAnimation = (feetContainerId, feets, duration) => {
  if (config.debug) logThis("init feetAnimation for header contatiner: " + feetContainerId);
  const feetContainerEl = document.getElementById(feetContainerId);
  const colors = [
    "#6F76B9",
    "#5EC7D1",
    "#F9F9D9",
    "#F3ED62",
    "#F16E56",
  ];
  const classChangeOpacity = feetContainerId + "opacity";

  feets.forEach((feet, i) => {
    const feetEl = document.createElement("div");
    switch (feet.orientation) {
      case 'sx':
        feetEl.innerHTML = feetSx(classChangeOpacity, feet.size, [0, 0, 66, 75], colors[feet.color]);
        break;
      case 'dx':
        feetEl.innerHTML = feetDx(classChangeOpacity, feet.size, [0, 0, 53, 72], colors[feet.color]);
        break;
      default:
    }
    feetEl.style.position = `absolute`;
    feetEl.style.transform = `rotate(${feet.rotate}deg)`;
    feetEl.style.left = `${feet.x}px`;
    feetEl.style.bottom = `${feet.y}px`;
    feetContainerEl.appendChild(feetEl);
  });
  gsap.fromTo(`.${classChangeOpacity}`,
    {
      opacity: 0,
      ease: "ease-in-out",
    },
    {
      opacity: 1,
      duration: duration,
      stagger: duration * 0.8,
      repeat: 100
    }
  )

  // var tl = gsap.timeline({ repeat: 100, repeatDelay: 0 });
  // var tl1 = gsap.timeline({ repeat: 100, repeatDelay: 0, yoyo: true });
  // const waitColor = durationOscillation * 6 - durationColor;

  // tl.to(".logo-containersecond-color",
  //   {
  //     fill: colors[0],
  //     duration: durationColor
  //   }, 0)
  // tl.to(".logo-containersecond-color",
  //   {
  //     fill: colors[1],
  //     duration: durationColor
  //   }, waitColor + durationColor)
  // tl.to(".logo-containersecond-color",
  //   {
  //     fill: colors[2],
  //     duration: durationColor
  //   }, 1 * waitColor + 2 * durationColor)
  // tl.to(".logo-containersecond-color",
  //   {
  //     fill: colors[3],
  //     duration: durationColor
  //   }, 2 * waitColor + 3 * durationColor)
  // tl.to(".logo-containersecond-color",
  //   {
  //     fill: colors[4],
  //     ease: "ease-in-out",
  //     duration: durationColor
  //   }, 3 * waitColor + 4 * durationColor)

  // tl1.to("#desk-logo",
  //   {
  //     skewX: '0deg',
  //     skewY: '0deg',
  //     ease: "ease-in-out",
  //     duration: durationOscillation
  //   }
  // )
  // tl1.to("#desk-logo",
  //   {
  //     skewX: '10deg',
  //     skewY: '-10deg',
  //     ease: "ease-in-out",
  //     duration: durationOscillation
  //   }
  // )
  // tl1.to("#desk-logo",
  //   {
  //     skewX: '-10deg',
  //     skewY: '10deg',
  //     ease: "ease-in-out",
  //     duration: durationOscillation
  //   }
  // )


  /*
  toggleActions: | enter | exit | comeback on screen | all the way back past the start | with these options "play pause resume reverse restart reset complete none"
  start: | first element relates to the trigger element | second value relates to the scroller | e.g."top center" top of the trigger element reach the center of the viewport. options "top center bottom" o "pixels percentage (relative to top)"
  end: everything that applay to start, plus select a value relative to the start. e.g. "+=300px" or a funtion e.g. ()=> "+=" + document.getElementById(globalWrapper).offsetWidth
  endTrigger: we can have a different element as end trigger
  */

  // ScrollTrigger.defaults({
  //   markers: false,
  // });
  // ScrollTrigger.create({
  //   animation: tl,
  //   toggleActions: "play play reverse reverse",
  //   trigger: `#${dbContainerId}`,
  //   start: "-20% 20%",
  //   endTrigger: `#${globalWrapper}`,
  //   end: "bottom 70%",
  //   // end: `+=${globalWrapperElHeight * 0.9}px`,
  //   // scrub: 1,  
  //   // pinSpacing: true,
  //   // pin: true,
  //   //scroller: "#container", //custom scroll element
  //   //horizontal: true,
  //   // id: "my-id",
  //   // toggleClass: "active", //the class can be added to any element
  //   //onUpdate: (self) => console.log("update", self.progress.toFixed(3)),
  //   //onToggle: (self) => console.log("update", self.isActive),
  //   //onEnter: () => console.log("enter"),
  //   //onLeave: () => console.log("leave"),
  //   //onEnterBack: () => console.log("enter back"),
  //   //onLeaveBack: () => console.log("all the way back")
  // })
}
export default feetAnimation;
